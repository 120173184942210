import React, {Fragment, useState, useRef} from 'react';
import Helmet from 'react-helmet';

import {usePageContext} from '@components/Page';
import {ThemeProvider, Colors, styled} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {useInterval} from '@util/useInterval';

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'applicationContainer'
})`
  margin: 5em 0 0;
  &.wide {
    height: ${({height}) => `${height}px`};
    margin: 8em auto 0;
  }
`;

const Application = ({pageContext}: {pageContext: object}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const [iframeHeight, setIframeHeight] = useState(1000);
  const getIframeHeight = () => {
    setIframeHeight(iframe.current.firstElementChild.offsetHeight);
  };

  const [callback, setCallback] = useState<(() => void) | null>(() => () => {
    if (window.Grnhse) {
      Grnhse.Iframe.load(pageContext.jobID);
      setCallback(null);
      setTimeout(getIframeHeight, 1000);
    }
  });

  useInterval(callback, 300);

  const iframe = useRef(null);

  return (
    <Fragment>
      <Helmet>
        <script src={process.env.GATSBY_API_GREENHOUSE_APPLICATIONS}></script>
      </Helmet>
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          bg: Colors.White,
          ctaIconFg: Colors.Blue,
          ctaIconBg: Colors.White,
          maxWidth: '100%',
        }}
      >
        <Container height={iframeHeight}>
          <div ref={iframe} id="grnhse_app"></div>
        </Container>
      </ThemeProvider>
    </Fragment>
  );
};

export default Application;
