import {useEffect} from 'react';

export const useInterval = (
  callback: (() => void) | null,
  delay: number,
): void => {
  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      if (typeof callback === 'function') {
        callback();
      }
    }
    if (typeof callback === 'function' && delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
};
